var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widgets",style:({
    position: 'absolute',
    width: _vm.val.width + 'px',
    height: _vm.val.height + 'px',
    left: _vm.val.left + 'px',
    top: _vm.val.top +'px',
    zIndex: _vm.val.zIndex
  })},[_c('img',{staticClass:"braid-image",attrs:{"title":_vm.val.title,"src":_vm.val.value || _vm.val.defaultValue}})])}
var staticRenderFns = []

export { render, staticRenderFns }