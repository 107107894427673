var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widgets",style:({
    position:'absolute',
    left: _vm.val.left + 'px' ,
    top:  _vm.val.top + 'px',
    width: _vm.val.width + 'px',
    minHeight: _vm.val.height + 'px',
    zIndex: _vm.val.style.zIndex,
    fontSize: _vm.val.style.FontSize + 'pt',
    color: _vm.val.style.FontColor,
  }),attrs:{"contenteditable":!!_vm.val.isEdit},domProps:{"innerHTML":_vm._s(_vm.val.value)},on:{"blur":function (e) { return _vm.updateText(e, _vm.val.uuid); }}})}
var staticRenderFns = []

export { render, staticRenderFns }