var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widgets",staticStyle:{"position":"absolute","overflow":"hidden"},style:({
    left: _vm.val.left + 'px' ,
    top:  _vm.val.top + 'px',
    width: _vm.val.width + 'px',
    height: _vm.val.height + 'px',
    textAlign: _vm.val.style.Alignment,
    fontSize: _vm.val.style.FontSize + 'pt',
    color: _vm.val.style.FontColor,
  })},[_c('table',{staticStyle:{"border-collapse":"collapse","font-size":"12px"},attrs:{"border":"1","width":"100%","cellspacing":"0","cellpadding":"2","bordercolor":"#000000"}},[_c('tr',_vm._l((_vm.columns),function(item){return _c('th',{key:item.name,attrs:{"width":item.name==='_seq'?40:''}},[_vm._v(_vm._s(item.title))])}),0),_c('tr',_vm._l((_vm.columns),function(item){return _c('td',{key:item.name},[_vm._v(_vm._s(item.value))])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }